//Import
@import "variables";
@import "typography";

.addButton
{
  background-color: #458c47;
  border-color: #458c47;
  color: $main-secondary-color;
  letter-spacing: $alpha-space;
  font-family: $main-labels;
  font-size: 14px;
  padding: 4px 10px;
  margin-left: 10px;

  i
  {
    color: $main-secondary-color;
    letter-spacing: $alpha-space;
    padding-right: 10px;
  }

  &:hover, &:active, &:focus
  {
    background-color: #458c47!important;
    border-color: #458c47!important;
    color: $main-secondary-color!important;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}

.removeButton {
  @extend .addButton;

  background-color: #F44336 !important;
  border-color: #F44336 !important;

  &:hover, &:active, &:focus {
    background-color: #F44336 !important;
    border-color: #F44336 !important;
  }

}

.addStructure, .completeButtons
{
  background-color: #458c47;
  border-color: #458c47;
  color: $main-secondary-color;
  letter-spacing: $alpha-space;
  font-family: $main-labels;
  font-size: 13px;
  padding: 4px 10px;

  &:hover, &:active, &:focus
  {
    background-color: #458c47!important;
    border-color: #458c47!important;
    color: $main-secondary-color!important;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}

//Extended Stepy Button Styles
.stepyBtn-style
{
  background-color: $system-color;
  border-color: $system-color;
  color: $main-secondary-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;

  &:hover, &:focus, &:active
  {
    color: $main-secondary-color!important;
    background-color: $system-color!important;
    border-color: $system-color!important;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}

.stepyBtn-back-style
{
  border: 2px solid $system-color;
  color: $system-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;

  &:hover, &:focus, &:active
  {
    border-color: $system-color!important;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}


//Responsive

@media only screen and (max-width: 1032px) {
  .addStructure, .completeButtons
  {
    font-size: 12px;
  }

  .addButton
  {
    font-size: 13px;
  }
}